import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../auth'
import { mapChoiceToRequest, postAllowDenyEntry } from '../api'
import { addEntryRequestIsWellFormed } from '../watchlist/allowdeny/validation' // Doh, bad folder structure...
import Type from '../watchlist/allowdeny/Type'

import OrgScopeSelector from '../OrgScopeSelector'
import { ChoiceSelector } from '../watchlist/allowdeny/Choice'

const AddAllowDenyEntry = ({ scope = '', type = '', match = '', onDone, onCancel }) => {
  const { token } = useAuth()
  const isCountry = type != null && type.indexOf('cntry') != -1

  // Edit + post new entry
  const [selScope, setSelScope] = useState(scope) // Init with provided scope prop
  const [newEntry, setNewEntry] = useState({
    saving: false,
    failed: null,
    req: { type, match, choice: '' },
    res: null,
  })
  const onMatchChange = (e) =>
    setNewEntry({ ...newEntry, req: { ...newEntry.req, match: e.target.value } })
  const onChoiceChange = (choice) => {
    setNewEntry({ ...newEntry, req: { ...newEntry?.req, choice } })
  }
  const postNewEntry = async () => {
    // TODO: If entry invalid, don't post
    setNewEntry({ ...newEntry, saving: true, failed: null, res: null })
    try {
      let saveReq = mapChoiceToRequest(newEntry.req)
      const res = await postAllowDenyEntry(token, selScope, saveReq)
      setNewEntry({
        ...newEntry,
        res,
        saving: false,
        failed: null,
        // Reset match text, ready for entry of the next entry on the UI (same type, match)
        req: { ...newEntry?.req, match: '' },
      })
      if (onDone != null) {
        onDone()
      }
    } catch (failed) {
      setNewEntry({ ...newEntry, failed, res: null, saving: false })
    }
  }
  return (
    <div className='add-allow-deny-entry'>
      <header>
        <h1>Add to allow/deny list</h1>
        {onCancel != null && (
          <button onClick={onCancel} title='Cancel' className='close clickable'>
            <i className='fas fa-times' />
          </button>
        )}
      </header>
      <div className='content'>
        <div className='field-with-label'>
          <label>Scope:</label>
          <OrgScopeSelector leafScope={scope} value={selScope} onChange={setSelScope} />
        </div>
        <div className='field-with-label'>
          <label>
            <Type type={type} />:
          </label>
          <div>
            <input type='text' value={newEntry.req?.match} onChange={onMatchChange} />
          </div>
        </div>
        <div className='field-with-label'>
          <label>Choice:</label>
          <div className='choice'>
            <ChoiceSelector value={newEntry?.req?.choice || ''} onChange={onChoiceChange} />
          </div>
        </div>
        {isCountry && (
          <p className='warning'>
            Warning: It is strongly recommended to not add entire countries to the allow/deny list.
            Please consider blocking individual users, IP addresses, or cards instead.
          </p>
        )}
        <footer className='actions'>
          <button className='cancel' onClick={onCancel}>
            Cancel
          </button>
          <button
            className='add'
            onClick={postNewEntry}
            disabled={newEntry.saving || !addEntryRequestIsWellFormed(newEntry.req)}
          >
            {newEntry.saving ? 'Saving...' : 'Add'}
          </button>
        </footer>
      </div>
    </div>
  )
}

AddAllowDenyEntry.propTypes = {
  /** If provided, this function is called on successful addition of the entry */
  onDone: PropTypes.func,
  /** If provided, this function is called upon user cancelletaion of the process before adding an entry*/
  onCancel: PropTypes.func,
  /** The default 'scope' of the thing that we want to add an entry for. For example, the ID of
   * the merchant for which a transaction was made. This is used to present the user with option(s)
   * as to where to attach the entry to - based on the full set of scopes that are visible to the
   * user. */
  scope: PropTypes.string.isRequired,
  /** Entry type, e.g. email, pan */
  type: PropTypes.string.isRequired,
  /** Entry match value - e.g. email address, or pattern */
  match: PropTypes.string,
}

export default AddAllowDenyEntry
