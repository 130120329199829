import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useParams, Link } from 'react-router-dom'
import {
  getTransaction,
  syncTransaction,
  getPSPMIDs,
  getRefundedTransaction,
  resendCallback,
  reconTransaction,
} from '../api'
import { useAuth } from '../auth'
import {
  isOperator,
  isController,
  hasContextContractID,
  maskMerchants,
  hasRefundAccess,
  isMintMyne,
} from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import AmountWithCurrency from '../AmountWithCurrency'
import TxnStatus from './TxnStatus'
import DateTime from '../DateTime'
import TxnScoreLine from './TxnScoreLine'
import ScoringResultsSummary from './ScoringResultsSummary'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { pathOr, path } from 'ramda'
import Country from '../Country'
import AllowDenyTest from '../allowdeny/AllowDenyTest'
import { majorErrorCodes, minorErrorCodes } from './error-codes'
import { useOrgScope } from '../org-scope'
import { usePSP } from '../PspContext'

const LIVE_UPDATE_INTERVAL = 30000 // ms

/** ViewTxn renders a single transaction. */
const ViewTxn = () => {
  const { token, email } = useAuth()
  const params = useParams()

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txn, setTxn] = useState()

  const fetchTxn = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransaction(token, params.id)
      setTxn(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useInterval(fetchTxn, LIVE_UPDATE_INTERVAL, true)
  const isUserMintMyne = isMintMyne(email)

  return (
    <section className='transaction'>
      <Header />
      <Helmet>
        <title>Transaction {txn ? txn.transaction_id : ''}</title>
      </Helmet>
      <div className='content'>
        {loading && !txn && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txn && (
          <div className='txn'>
            <TxnCore txn={txn} token={token} />
            <TxnCustomer txn={txn} />
            <TxnCard txn={txn} />
            <TxnScore txn={txn} />
            {!isUserMintMyne && <TxnReqMsg txn={txn} />}
          </div>
        )}
        {/*<pre>{JSON.stringify(txn, null, ' ')}</pre>*/}
      </div>
    </section>
  )
}

const ResentCallbackBtn = ({ txnID, token, disabled }) => {
  const [sent, setSent] = useState(false)
  const sendCallback = async () => {
    setSent(true)
    try {
      await resendCallback(token, txnID)
      window.location.reload(false)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <button
      className={classnames({ sync: true, busy: sent })}
      onClick={sendCallback}
      disabled={sent || disabled}
    >
      <i className='fas fa-satellite-dish' />
      {sent ? 'Callback sent' : 'Resend callback'}
    </button>
  )
}

/* Renders a button that allows for an idempotent sync with upstream. */
const SyncTxnBtn = ({ txnID, token, disabled }) => {
  const [syncingTxn, setSyncingTxn] = useState(false)
  const syncTxn = async () => {
    setSyncingTxn(true)
    try {
      await syncTransaction(token, txnID)
      setSyncingTxn(false)
      window.location.reload(false)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <button
      className={classnames({ sync: true, busy: syncingTxn })}
      onClick={syncTxn}
      disabled={syncingTxn || disabled}
    >
      <i className='fas fa-sync' />
      {syncingTxn ? 'Checking upstream...' : 'Check upstream'}
    </button>
  )
}
SyncTxnBtn.propTypes = {
  txnID: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

/* Renders a button that allows for a transaction recon sync  */
const ReconTxnBtn = ({ txnID, token }) => {
  const [reconcilingTxn, setReconcilingTxn] = useState(false)
  const reconTxn = async () => {
    setReconcilingTxn(true)
    try {
      await reconTransaction(token, txnID)
      setReconcilingTxn(false)
      window.location.reload(false)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <button
      className='btn-recon-small'
      onClick={reconTxn}
      disabled={reconcilingTxn}
      title='Verify recon status'
    >
      <i className='fas fa-check-double' />
    </button>
  )
}
ReconTxnBtn.propTypes = {
  txnID: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

/* Renders transaction ID - with optional click-through to upstream back-office
 * in a very simple / insecure way. */
const TxnID = ({ psp_id, psp_transaction_id }) =>
  psp_id == 'decta' ? (
    <a
      href={`https://gate.decta.com/merchants/en/merchant/timeline/${psp_transaction_id}/logs`}
      target='_blank'
      rel='noreferrer'
    >
      {psp_transaction_id}
    </a>
  ) : (
    <span>{psp_transaction_id}</span>
  )

TxnID.propTypes = {
  psp_id: PropTypes.string,
  psp_transaction_id: PropTypes.string,
}

/** Core / basic transaction details */
const TxnCore = ({ txn, token }) => {
  const { roles, email } = useAuth()
  const { psps } = usePSP()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles)
  const isUserMaskMerchants = maskMerchants(roles)
  const isUserMintMyne = isMintMyne(email)
  const isContractUser = hasContextContractID(roles)
  const userHasRefundAccess = hasRefundAccess(roles)

  const { byID } = useOrgScope()
  const [pspMidLabel, setPSPMidLabel] = useState('')
  const [refunded, setRefunded] = useState()
  const [refundsEnabled, setRefundsEnabled] = useState(false)
  const [txnFunctionsEnabled, setTxnFunctionsEnabled] = useState(false)

  // Clean up KYC Rule list from duplicates
  const kycRule = Array.from(new Set(txn?.kyc_rule?.split(';')))

  const fetchPSPCredential = async (pspID, pspCredentialId) => {
    if (pspID && pspCredentialId) {
      try {
        getPSPMIDs(token, email, pspID).then((psps) => {
          const mid = psps.find((x) => x.pspCredentialId == pspCredentialId)
          if (mid) {
            setPSPMidLabel(mid.pspCredentialLabel)
            setTxnFunctionsEnabled(mid.active)
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  // For V1 we can use the total refunded amount as we only do full refunds for V1
  const fetchTxnRefund = async (transactionId) => {
    try {
      const result = await getRefundedTransaction(token, transactionId)
      if (result && result.length > 0) {
        setRefunded(result[result.length - 1]) // show only most recent result
      }
    } catch (e) {
      //Do nothing as this might be a transaction not yet refunded
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchPSPCredential(txn.psp_id, txn.psp_credential_id)
      await fetchTxnRefund(txn.transaction_id)
    })()
  }, [])

  useEffect(() => {
    const checkCapabilities = () => {
      if (txn.psp_id && psps.length > 0) {
        const psp = psps.find((x) => x.id === txn.psp_id)
        if (psp) {
          setRefundsEnabled(psp.Capabilities.Refunds)
        } else {
          console.log(`PSP with ID ${txn.psp_id} not found`)
        }
      }
    }

    checkCapabilities()
  }, [txn.psp_id, psps])

  return (
    <div className='txn-core'>
      <TxnStatusBlock txn={txn}>
        {(isUserOperator || userHasRefundAccess) && txn.status == 'ok' && (
          <span className='refund-status-block-events'>
            <Link
              to={`/transaction/${txn.transaction_id}/manualchargeback`}
              title='Manual Chargeback'
            >
              <i className='fas fa-comment-dollar refund-events' />
            </Link>
          </span>
        )}
        {(isUserOperator || userHasRefundAccess) && txn.status == 'ok' && (
          <span className='refund-status-block-events'>
            <Link to={`/transaction/${txn.transaction_id}/manualrefund`} title='Manual Refund'>
              <i className='fas fa-money-check refund-events' />
            </Link>
          </span>
        )}
        {(isUserOperator || userHasRefundAccess) &&
          txn.status == 'ok' &&
          txn.psp_transaction_id !== '' &&
          refundsEnabled && (
            <span className='refund-status-block-events'>
              <Link to={`/transaction/${txn.transaction_id}/refund`} title='Direct Refund'>
                <i className='fas fas fa-credit-card refund-events' />
              </Link>
            </span>
          )}
        <h1>
          <i className={`${txn.sale_request.test ? 'fa fa-flask' : 'fas fa-database'}`}></i>
          Transaction {txn.sale_request.test ? '[TEST]' : ''}
        </h1>
      </TxnStatusBlock>
      <table>
        <tbody>
          {txn.status == 'failed' && txn.failure_code && (
            <tr className='failure_code error'>
              <td className='key'>Failure code</td>
              <td className='val'>
                <TxnFailureDetail {...txn} />
              </td>
            </tr>
          )}
          {txn.status == 'failed' && txn.failure_code_minor && (
            <tr className='failure_code_minor error'>
              <td className='key'>Failure code (minor)</td>
              <td className='val'>
                <TxnFailureDetailMinor {...txn} />
              </td>
            </tr>
          )}
          <tr className='transaction_id'>
            <td className='key'>Transaction ID</td>
            <td className='val'>{txn.transaction_id}</td>
          </tr>

          {txn.order_ref && (
            <tr className='order_ref'>
              <td className='key'>{isUserMintMyne ? 'Order ref' : 'Merchant order ref'}</td>
              <td className='val'>{txn.order_ref}</td>
            </tr>
          )}
          {!isUserMintMyne && txn.merchant_id && (
            <tr className='merchant_id'>
              <td className='key'>Merchant</td>
              <td className='val'>
                {isUserMaskMerchants ? (
                  byID?.[txn.contract_id]?.name?.split('-')[0] || txn.contract_id
                ) : (isUserOperator || isContractUser || isUserController) &&
                  !isUserMaskMerchants ? (
                  <>
                    <Link to={`/merchant/${txn.merchant_id}`}>
                      {byID?.[txn.merchant_id]?.name || txn.merchant_id}
                    </Link>{' '}
                    <Link
                      className='search-txns'
                      title='Find matching transactions'
                      to={`/transactions?merchant_any=${txn.merchant_id}`}
                    >
                      <i className='fas fa-search' />
                    </Link>
                  </>
                ) : (
                  byID?.[txn.merchant_id]?.name || txn.merchant_id
                )}
              </td>
            </tr>
          )}
          {txn.contract_id && (
            <tr className='merchant_id'>
              <td className='key'>Contract</td>
              <td className='val'>{byID?.[txn.contract_id]?.name || txn.contract_id}</td>
            </tr>
          )}
          {txn.total_requested && (
            <tr className='total_requested'>
              <td className='key'>Total requested</td>
              <td className='val'>
                <AmountWithCurrency {...txn.total_requested} />
              </td>
            </tr>
          )}
          {txn.total_processed &&
            txn.total_requested &&
            (txn.total_processed.currency != txn.total_requested.currency ||
              (txn.total_settled &&
                txn.total_requested.currency != txn.total_settled.currency)) && (
              <tr className='total_requested'>
                <td className='key'>Total processed</td>
                <td className='val'>
                  <AmountWithCurrency {...txn.total_processed} />
                </td>
              </tr>
            )}
          {txn.total_settled && (
            <tr className='total_settled'>
              <td className='key'>Total settled</td>
              <td className='val'>
                <AmountWithCurrency {...txn.total_settled} />
              </td>
            </tr>
          )}
          {refunded ? (
            <tr className='total_settled'>
              <td className='key'>
                {refunded.status == 'success' ? 'Total refunded' : `Refund (${refunded.status})`}
              </td>
              <td className='val'>
                <AmountWithCurrency {...refunded.amount} />
              </td>
            </tr>
          ) : null}
          {txn.created_at && (
            <tr className='psp_transaction_at'>
              <td className='key'>Received at</td>
              <td className='val'>
                <DateTime at={txn.created_at} />
              </td>
            </tr>
          )}
          {txn.updated_at && (
            <tr className='psp_transaction_at'>
              <td className='key'>Last Updated</td>
              <td className='val'>
                <DateTime at={txn.updated_at} />
              </td>
            </tr>
          )}
          {(isUserOperator || isContractUser) && txn.psp_id && (
            <tr className='psp_id'>
              <td className='key'>PSP</td>
              <td className='val'>
                <span className='psp-credential-id'>{txn.psp_id}</span>
                {txn.psp_credential_id && (
                  <span className='psp-credential'>
                    Key ID: <span className='id'>{txn.psp_credential_id}</span>
                    <div className='psp-credential-mid'>
                      PSP MID: <span className='id'>{pspMidLabel}</span>
                    </div>
                  </span>
                )}
              </td>
            </tr>
          )}
          {txn.three_ds_info &&
            (txn.three_ds_info.version ||
              txn.three_ds_info.flow ||
              txn.three_ds_info.transaction_id) && (
              <tr className='three_ds_info'>
                <td className='key'>3DS</td>
                <td className='val'>{`${
                  txn.three_ds_info.version ? txn.three_ds_info.version : '?'
                } ${txn.three_ds_info.flow ? txn.three_ds_info.flow : ''} ${
                  txn.three_ds_info.transaction_id ? txn.three_ds_info.transaction_id : ''
                }`}</td>
              </tr>
            )}

          {txn.psp_transaction_id && (
            <tr className='psp_transaction_id'>
              <td className='key'>Upstream Transaction ID</td>
              <td className='val'>
                <TxnID {...txn} />
              </td>
            </tr>
          )}

          {isUserOperator && (
            <tr className='rule_contract'>
              <td className='key'>Contract Pre-Assignment Rule</td>
              <td className='val'>{txn.contract_rule ? txn.contract_rule : '-'}</td>
            </tr>
          )}

          {isUserOperator && (
            <tr className='rule_kyc'>
              <td className='key'>KYC Rule</td>
              <td>
                {kycRule &&
                  kycRule?.map((rule) => (
                    <p className='val' key={rule}>
                      {rule ? rule : '-'}
                    </p>
                  ))}
              </td>
            </tr>
          )}

          {isUserOperator && (
            <tr className='rule_routing'>
              <td className='key'>Routing Rule</td>
              <td className='val'>{txn.routing_rule}</td>
            </tr>
          )}

          {(isUserOperator || isUserController) && txn.recon_status && (
            <tr className='psp_transaction_id'>
              <td className='key'>Recon Status</td>
              <td className='val'>
                {txn.recon_status}
                {txn.status !== 'refunded' && txn.psp_id !== undefined && (
                  <ReconTxnBtn txnID={txn.transaction_id} token={token} />
                )}
              </td>
            </tr>
          )}

          {(isUserOperator || isUserController) && txn.callback && (
            <tr className='psp_transaction_at'>
              <td className='key'>Callback Status</td>
              <td className='val'>
                {txn.callback.succeeded ? 'Sent at ' : 'Aborted at '}
                <DateTime at={txn.callback.resolved_at} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <footer className='actions'>
        {(isUserOperator || isUserController) && (
          <SyncTxnBtn txnID={txn.transaction_id} token={token} disabled={!txnFunctionsEnabled} />
        )}

        {(isUserOperator || isUserController) && (
          <Link to={`/transaction/${txn.transaction_id}/status`}>
            <button disabled={!txnFunctionsEnabled}>
              <i className='fas fa-comment-dollar' /> Change status
            </button>
          </Link>
        )}

        {(isUserOperator || isUserController) &&
          ['ok', 'failed', 'refunded', 'charged_back'].includes(txn.status) && (
            <ResentCallbackBtn
              txnID={txn.transaction_id}
              token={token}
              disabled={!txnFunctionsEnabled}
            />
          )}
      </footer>
    </div>
  )
}

TxnCore.propTypes = {
  txn: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

const TxnStatusBlock = ({ txn, children }) => {
  return (
    <div
      className={classnames({
        test: txn.sale_request.test,
        'status-block': true,
        [txn.status]: txn.status,
        block: true,
      })}
    >
      {children}
      <div className='status-line'>
        <span className='label'>Status</span>
        <TxnStatus {...txn} />
      </div>
    </div>
  )
}
TxnStatusBlock.propTypes = {
  txn: PropTypes.object.isRequired,
  children: PropTypes.any,
}

/** Renders detail (code and message) of the MAJOR failure code for a transaction */
const TxnFailureDetail = ({ failure_code }) => {
  const detail = majorErrorCodes.get(failure_code)
  return (
    <span className='failure-detail'>
      <strong className='code'>{failure_code || '?'}</strong>
      {detail && ' : '}
      {detail && <span className='detail'>{detail}</span>}
      {/* Find other transactions with same failure - 1 week by default */}
      <Link
        className='search-txns'
        title='Find other transactions with same failure code'
        to={`/transactions?status_any=failed&from=m7d&to=now&error_code_major=${failure_code}`}
      >
        <i className='fas fa-search' />
      </Link>
    </span>
  )
}

TxnFailureDetail.propTypes = {
  // Major failure code
  failure_code: PropTypes.number.isRequired,
}

const TxnFailureDetailMinor = ({ failure_code_minor }) => {
  const detail = minorErrorCodes.get(failure_code_minor)
  return (
    <span className='failure-detail'>
      <strong className='code'>{failure_code_minor || '?'}</strong>
      {detail && ' : '}
      {detail && <span className='detail'>{detail}</span>}
    </span>
  )
}

TxnFailureDetailMinor.propTypes = {
  failure_code_minor: PropTypes.number.isRequired,
}

/** Informatoin about the customer, including metadata */
const TxnCustomer = ({ txn }) => {
  const email = path(['sale_request', 'card_holder', 'contact', 'email'], txn)
  return (
    <div className='txn-customer'>
      <h1>
        <i className='fas fa-user-shield'></i> Customer
      </h1>
      <table className='basic-info'>
        <tbody>
          <tr className='name'>
            <td className='key'>Name</td>
            <td className='val'>
              {pathOr('?', ['sale_request', 'card_holder', 'name', 'first'], txn)}{' '}
              {pathOr('?', ['sale_request', 'card_holder', 'name', 'last'], txn)}
            </td>
          </tr>
          <tr className='email'>
            <td className='key'>Email</td>
            <td className='val'>
              <AllowDenyTest scope={txn?.merchant_id} type='email' value={email || ''}>
                {email && (
                  <Link
                    className='search-txns'
                    title='Find matching transactions'
                    to={`/transactions?email=${encodeURIComponent(email)}`}
                  >
                    <i className='fas fa-search' />
                  </Link>
                )}{' '}
                {email || '-'}
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='phone'>
            <td className='key'>Phone</td>
            <td className='val'>
              {pathOr('-', ['sale_request', 'card_holder', 'contact', 'phone'], txn)}{' '}
            </td>
          </tr>
          <tr className='country billing-addr'>
            <td className='key'>Country (as provided)</td>
            <td className='val'>
              <Country iso={path(['country_code'], txn)} />
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Device and Networking</h2>
      <table className='device-networking'>
        <tbody>
          <tr className='ip provided'>
            <td className='key'>IP (as provided)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='ip_cust'
                value={path(['sale_request', 'device', 'ip'], txn) || ''}
              >
                {pathOr('-', ['sale_request', 'device', 'ip'], txn)}
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='ip detected'>
            <td className='key'>IP (as detected)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='ip_cust'
                value={path(['meta', 'customer_ip'], txn) || ''}
              >
                {pathOr('-', ['meta', 'customer_ip'], txn)}
              </AllowDenyTest>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Location</h2>
      <table className='location'>
        <tbody>
          <tr className='country ip-lookup'>
            <td className='key'>Country (from IP)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='cntry_ip_cust'
                value={path(['meta', 'ip_lookup', 'CustomerPhysicalAddress', 'country'], txn) || ''}
              >
                <Country
                  iso={pathOr(
                    '-',
                    ['meta', 'ip_lookup', 'CustomerPhysicalAddress', 'country'],
                    txn
                  )}
                />
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='country bin'>
            <td className='key'>Country (Card Issue)</td>
            <td className='val'>
              <AllowDenyTest
                scope={txn?.merchant_id}
                type='cntry_bin'
                value={path(['meta', 'card_meta', 'issuing_country'], txn) || ''}
              >
                <Country iso={pathOr('-', ['meta', 'card_meta', 'issuing_country'], txn)} />
              </AllowDenyTest>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>History</h2>
      <table className='history'>
        <tbody>
          <tr className='success_count'>
            <td className='key'>Previous successes at time of transaction</td>
            <td className='val'>
              {txn.success}
              {email && (
                <Link
                  className='search-txns'
                  title='View previous successful transactions for this customer'
                  to={`/transactions?status_any=ok&email=${encodeURIComponent(email)}`}
                >
                  <i className='fas fa-search' />
                </Link>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

TxnCustomer.propTypes = {
  txn: PropTypes.object.isRequired,
}

const TxnReqMsg = ({ txn }) => (
  <div className='txn-req-json'>
    <h1>API Request</h1>
    <p>This is the JSON API request originally posted for this transaction from the merchant:</p>
    <JSONInput
      placeholder={txn.sale_request || 'No request available for this transaction'}
      locale={locale}
      theme='light_mitsuketa_tribute'
      colors={{ background: '#f3f3f3' }}
      width='100%'
    />
  </div>
)
TxnReqMsg.propTypes = {
  txn: PropTypes.object.isRequired,
}

const TxnScore = ({ txn }) => (
  <div className='txn-score-summary'>
    <h1>
      <i className='fas fa-fingerprint'></i> Transaction score <TxnScoreLine txn={txn} />
    </h1>
    {txn.score && <ScoringResultsSummary {...txn.score} />}
    {!txn.score && (
      <p>
        This transaction was not scored - either because it was rejected immediately, or because a
        required step such as customer verification is still pending.
      </p>
    )}
  </div>
)
TxnScore.propTypes = {
  txn: PropTypes.object.isRequired,
}

/** Information about the card used for a transaction */
const TxnCard = ({ txn }) => {
  //  const { roles } = useAuth()
  //  const isUserOperator = isOperator(roles)
  //  const isContractUser = hasContextContractID(roles)
  return (
    <div className='txn-card'>
      <h1>
        <i className='far fa-credit-card'></i> Card
      </h1>
      <table>
        <tbody>
          <tr className='id'>
            <td className='key'>ID</td>
            <td className='val'>
              <AllowDenyTest scope={txn?.merchant_id} type='cardid' value={txn.card?.id || ''}>
                {txn.card?.id && (
                  <Link
                    className='search-txns'
                    title='Find matching transactions'
                    to={`/transactions?card_cnts=${encodeURIComponent(txn.card?.id)}`}
                  >
                    <i className='fas fa-search' />
                  </Link>
                )}{' '}
                {txn.card?.id || '-'}
              </AllowDenyTest>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>BIN</td>
            <td className='val'>
              <>
                <Link to={`/transactions?card_cnts=${txn.card?.bin}`}>{txn.card?.bin || '-'}</Link>{' '}
                <Link
                  className='search-txns'
                  title='Find matching transactions'
                  to={`/transactions?card_cnts=${txn.card?.bin}`}
                >
                  <i className='fas fa-search' />
                </Link>
              </>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>PAN</td>
            <td className='val'>
              <div>{`${txn.card?.pan || '-'}`}</div>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>Issuer</td>
            <td className='val'>
              <div>{`${txn.meta?.card_meta?.issuer || '-'}`}</div>
            </td>
          </tr>
        </tbody>
      </table>
      {txn.provider ? <TxnProvider txn={txn} /> : <div />}
    </div>
  )
}

TxnCard.propTypes = {
  txn: PropTypes.object.isRequired,
}

const TxnProvider = ({ txn }) => {
  return (
    <div className='txn-card'>
      <h1>
        <i className='far fa-id-card'></i> Provider Info
      </h1>
      <table>
        <tbody>
          <tr className='bin'>
            <td className='key'>Card Holder</td>
            <td className='val'>
              <div>{`${txn.provider?.quickbit?.cardholder_name || '-'}`}</div>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>SSN</td>
            <td className='val'>
              <div>{`${txn.provider?.quickbit?.ssn || '-'}`}</div>
            </td>
          </tr>
          <tr className='bin'>
            <td className='key'>SSN Country Code</td>
            <td className='val'>
              <Country iso={path(['country_code'], txn)} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

TxnProvider.propTypes = {
  txn: PropTypes.object.isRequired,
}

export default ViewTxn
