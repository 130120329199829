import React, { useEffect } from 'react'
import { Rule } from './interfaces'

const ruleRegex =
  /rule (?<rule>\S+)( "(?<desc>[^"]*)")?( salience (?<salience>\d+))? ?{ when (?<when>.*) then(?<then>.*?) }/g // Honestly this one is pretty simple I don't know why you're angry at me 🤷

export const parseRules = (raw: string): Rule[] => {
  // simplify the rule input by stripping out any formatting and putting it one-rule-per-line
  let normalisedRules = raw.replaceAll(/[\s\r\n]+/g, ' ').replaceAll(/} rule/g, '}\r\nrule')

  const rules: Rule[] = []

  // gather out rules, and extract the component parts
  let match: RegExpExecArray | null = null
  let id = 1
  while ((match = ruleRegex.exec(normalisedRules)) !== null && match.groups) {
    let rule: Rule = {
      title: (match.groups['rule'] ?? '').trim(),
      salience: parseInt(match.groups['salience'] ?? '0'),
      description: match.groups['desc']?.trim(),
      when: (match.groups['when'] ?? '').trim(),
      then: match.groups['then']
        .split(';')
        .map((x) => x.trim())
        .filter((x) => x != ''),
      //complete: false,
      id: id++,
    }
    //rule.complete = !!rule.then.find((x) => x == 'Complete()')
    rule.then = rule.then.filter(
      (x) => x != 'Complete()' && !x.includes('SetRule(') && !x.includes('Retract(')
    )

    rules.push(rule)
  }

  return rules
}

const completingThen = [
  // routing
  'AddRoute(',
  'AddRoutes(',
  'AddWeightedRoute(',
  'SetDestination(',
  'SetDestinationID(',
  'Block(', // also used in scoring
  'BlockWithCode(',
  'UsePools(',
  // assignment
  'SelectedContractID',
  // kyc
  'KYCRequireQuestions', // TODO refactor this into a KYC call function
  'KYCRequireIdentityVerification',
  'RequireQuestions',
  'RequireIdentityVerification',
  'RequireScreening',
  // scoring
  'Bypass(',
]
export const formatRules = (rules: Rule[], includeSetRule: boolean): string => {
  // salience override isn't strictly necessary, rules should have them set correctly anyway
  const salienceIncrement = 1000
  let salience = rules.length * salienceIncrement
  let output = ''
  for (const rule of rules) {
    const then = rule.then.map((x) => x.trim()).filter((x) => x != 'Complete()')
    const complete = then.some((x) => completingThen.some((y) => x.includes(y)))
    // 🔥 🐶 this is fine 🔥
    output += `rule ${rule.title}${
      rule.description ? ` "${rule.description}"` : ''
    } salience ${salience} {\r\nwhen\r\n\t${rule.when}\r\nthen\r\n${then
      .map((x) => `\t${x};\r\n`)
      .join('')}${
      complete
        ? `${includeSetRule ? `\tResult.SetRule("${rule.title}");\r\n` : ''}\tComplete();\r\n`
        : `\tRetract("${rule.title}");\r\n`
    }}\r\n`
    salience -= salienceIncrement
  }

  return output
}

// Updates the height of an element when the value changes.
export const useAutosize = (
  ref: React.RefObject<HTMLElement>,
  value: string,
  padding: number = 0
) => {
  useEffect(() => {
    if (ref.current) {
      resizeElement(ref.current, padding)
    }
  }, [value])
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (ref.current) {
        resizeElement(ref.current, padding)
      }
    })
  }, [])
}

const resizeElement = (element: HTMLElement, padding: number) => {
  if (!element) {
    return
  }

  // We need to reset the height momentarily to get the correct scrollHeight for the element
  element.style.height = '0px'
  // We then set the height directly
  element.style.height = `${element.scrollHeight + padding}px`
}

const termsRegex =
  /(?<=^|\s|[(!])(?<term>(?:Txn|Result|Risk)\.(?:[a-zA-Z]+\.)*(?:[a-zA-Z]+))(?=\(|\s?[!><=)+*\-%]|$)/g
const termWhitelist = [
  // Txn properties
  'Txn.AmountEUR',
  'Txn.Amount',
  'Txn.Type',
  'Txn.Currency',
  'Txn.ContractID',
  'Txn.Contract',
  'Txn.MerchantID',
  'Txn.Merchant',
  'Txn.GroupMerchantID',
  'Txn.GroupMerchant',
  'Txn.SourceID',
  'Txn.Source',
  'Txn.DestinationID',
  'Txn.Destination',
  // Txn functions
  'Txn.IsCountry',
  'Txn.AddTag',
  'Txn.HasTag',
  'Txn.RemoveTag',
  'Txn.SetVar',
  'Txn.GetVar',
  'Txn.IsMultipleOf',
  'Txn.IsRequestedAmountMultipleOf',
  'Txn.RandomPercent',
  'Txn.RandomPercentByEmailDate',
  // Txn.Card properties
  'Txn.Card.BIN',
  'Txn.Card.Brand',
  'Txn.Card.Issuer',
  'Txn.Card.Country',
  'Txn.Card.TestCard',
  'Txn.Card.Business',
  'Txn.Card.Government',
  'Txn.Card.Prepaid',
  'Txn.Card.Voucher',
  'Txn.Card.GiftCard',
  'Txn.Card.Virtual',
  'Txn.Card.Successes',
  'Txn.Card.EU',
  'Txn.Card.ESM',
  'Txn.Card.ESMPlus',
  'Txn.Card.OnAllowList',
  'Txn.Card.OnDenyList',
  // Txn.IP properties
  'Txn.IP.Country',
  'Txn.IP.DataCenter',
  'Txn.IP.Bogon',
  'Txn.IP.FakeCrawler',
  'Txn.IP.Crawler',
  'Txn.IP.WebProxy',
  'Txn.IP.PublicProxy',
  'Txn.IP.CGIProxy',
  'Txn.IP.TorExitNode',
  'Txn.IP.VPN',
  'Txn.IP.EU',
  'Txn.IP.ESM',
  'Txn.IP.ESMPlus',
  'Txn.IP.OnAllowList',
  'Txn.IP.OnDenyList',
  // Txn.BillingAddress properties
  'Txn.BillingAddress.Line1',
  'Txn.BillingAddress.City',
  'Txn.BillingAddress.PostalCode',
  'Txn.BillingAddress.Country',
  'Txn.BillingAddress.EU',
  'Txn.BillingAddress.ESM',
  'Txn.BillingAddress.ESMPlus',
  // Txn.Customer properties
  'Txn.Customer.Successes',
  'Txn.Customer.Email',
  'Txn.Customer.DisposableEmailDomain',
  'Txn.Customer.Age',
  'Txn.Customer.OnAllowList',
  'Txn.Customer.OnDenyList',
  // Txn.Customer.Identity properties
  'Txn.Customer.Identity.Verified',
  'Txn.Customer.Identity.HasAge',
  'Txn.Customer.Identity.Age',
  // Txn.Customer functions
  'Txn.Customer.ConsecutiveSuccesses',
  'Txn.Customer.IsKYCPassedByOwner',
  'Txn.Customer.IsKYCPassedByOwnerID',
  'Txn.Customer.SuccessesByDestination',
  'Txn.Customer.SuccessesBySource',
  'Txn.Customer.SuccessesBySourceID',
  'Txn.Customer.SuccessesByDestinationID',
  'Txn.Customer.PreviousSettledEUR',
  'Txn.Customer.PreviousSettledEURDest',
  // Txn.Internal properties
  'Txn.Internal.TestPSPProcessors',
  // Temporary, remove after validating Query better
  'Txn.Query',
  // Result properties
  'Result.SelectedRule',
  'Result.ProcessingCurrency',
  // Result functions
  'Result.SetRule',
  'Result.SetDestination',
  'Result.SetDestinationID',
  'Result.Block', // routing and scoring
  'Result.BlockWithCode',
  'Result.AddRoutes',
  'Result.SetRoutingMode',
  'Result.AddWeightedRoute',
  'Result.Clear',
  'Result.Bypass',
  'Result.AddLocationScore',
  'Result.AddCardScore',
  'Result.AddIdentityScore',
  'Result.AddHistoryScore',
  'Result.AddVelocityScore',
  'Result.AddProxyScore',
  'Result.AddFraudScore',
  'Result.AddDeviceScore',
  'Result.EnableBypass',
  'Result.RequireQuestions',
  'Result.RequireScreening',
  'Result.RequireIdentityVerification',
  'Result.SetVerifyWhitelist',
  'Result.AddVerifyPaths',
  'Result.UsePools',
  // Risk properties, Ekata
  'Risk.Ekata.Loaded',
  'Risk.Ekata.EmailValid',
  'Risk.Ekata.EmailValidToName',
  'Risk.Ekata.EmailFirstSeenDays',
  'Risk.Ekata.PhoneValid',
  'Risk.Ekata.PhoneValidToName',
  'Risk.Ekata.IPDistance',
  'Risk.Ekata.AddressValidityLevel',
  'Risk.Ekata.RiskScore',
  // Risk properties, Seon
  'Risk.Seon.Loaded',
  'Risk.Seon.EmailValid',
  'Risk.Seon.EmailValidToName',
  'Risk.Seon.EmailFirstSeenDays',
  'Risk.Seon.EmailSocialMediaList',
  'Risk.Seon.EmailMarketplaceList',
  'Risk.Seon.EmailStreamingList',
  'Risk.Seon.EmailOtherList',
  'Risk.Seon.PhoneValid',
  'Risk.Seon.PhoneFirstSeenDays',
  'Risk.Seon.PhoneCountry',
  'Risk.Seon.PhoneServiceList',
  'Risk.Seon.IPValid',
  'Risk.Seon.IPFirstSeenDays',
  'Risk.Seon.RiskScore',
  'Risk.Seon.IsFraudulent',
  'Risk.Seon.IsSanction',
  'Risk.Seon.IsPEP',
  // Pool based properties / functions
  'Pool.New',
  'Pool.NewFromOther',
  'Pool.Add',
  'Pool.AdjustWeight',
  'Pool.SetWeight',
  'Pool.Remove',
  'Pool.SetRoutingMode',
  'Pool.RemoveIfQueryTotalEUROver',
  'Pool.RemoveIfQueryTransactionsOver',
]
export const validateTerms = (text: string) => {
  const match = (text ?? '').match(termsRegex)
  if (match) {
    for (let term of match) {
      term = removeWhitelistedBuiltins(term)
      if (!termWhitelist.includes(term)) {
        return term
      }
    }
  }
}

export const removeWhitelistedBuiltins = (term: string) => {
  // trim whitelisted functions
  if (term.endsWith('.In')) {
    return term.slice(0, -3)
  }
  if (term.endsWith('.HasSuffix')) {
    return term.slice(0, -10)
  }
  if (term.endsWith('.HasPrefix')) {
    return term.slice(0, -10)
  }
  if (term.endsWith('.ToUpper')) {
    return term.slice(0, -8)
  }
  if (term.endsWith('.ToLower')) {
    return term.slice(0, -8)
  }
  if (term.endsWith('.Contains')) {
    return term.slice(0, -9)
  }
  if (term.endsWith('.Len')) {
    return term.slice(0, -4)
  }

  return term
}
